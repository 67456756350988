@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
.swal2-popup{
    background-color: black;
    border-radius: 2em;
    background-color: #eeeeee;
    font-family:'Ubuntu' !important;
    max-width: 40%;
    font-weight: 400;
    margin: auto;
    height: max-content;
    font-size: 0.6em;
}

@media (max-width:600px){
    .swal2-popup{
        max-width:80% !important;
    }    
}