@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

.backed{
    text-align: center;
    font-size: 1.3em;
    font-family: 'Ubuntu';
    font-weight: 500;
}
.sponsors{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}
.sponsors img{
    width: 350px;
    margin: auto;
}


@media (width<370px){
    .sponsors img{
        margin-left: 0.6em;
    }
}