.image_conatiner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  column-gap: 5rem;
  width: 100%;
}

.image_conatiner div img {
  height: 20rem;
  width: auto;
}

.one {
  transform: translateY(-50px);
  animation: movingone 1.5s alternate infinite;
  transition: transform 0.7s;
}

.two {
  transform: translateY(50px);
  animation: movingtwo 1.5s alternate infinite;
  transition: transform 0.1s;
}

.three {
  transform: translateY(-50px);
  animation: movingthree 1.5s alternate infinite;
  transition: transform 0.7s;
}

@keyframes movingone {
  0% {
    transform: translateY(50px);
  }
}

@keyframes movingtwo {
  0% {
    transform: translateY(-50px);
  }
}

@keyframes movingthree {
  0% {
    transform: translateY(50px);
  }
}

@media only screen and (max-width: 1200px) {
  .image_conatiner div img {
    height: 15rem;
  }
}
@media only screen and (max-width: 850px) {
  .image_conatiner {
    column-gap: 2rem;
  }
  .image_conatiner div img {
    height: 13rem;
  }
}
@media only screen and (max-width: 700px) {
  .image_conatiner {
    flex-direction: column;
    justify-content: center;
    width: 95%;
    margin: auto;
    gap: 4em;
  }
  .image_conatiner div img {
    width: 55%;
    height: auto;
    margin: auto;
  }
  .one,
  .two {
    transform: translateY(-35px) translateX(10px);
  }
  .two {
    transform: translateY(-35px) translateX(-10px);
  }
  .one,
  .two,
  .three {
    animation: phoneMoving 1.2s alternate infinite;
  }
  @keyframes phoneMoving {
    0% {
      transform: translateY(35px);
    }
  }
}
