#experience {
  z-index: 1;
  margin-bottom: 100px;
}
.experienced_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  gap: 3rem;
}
.info-container {
  background-color: #eeeeee;
}
.experienced_container:hover .frontend_container {
  transform: translateX(90px);
}

.experienced_container:hover .backend_container {
  transform: translateX(-90px);
}

.frontend_container,
.backend_container {
  width: 35%;
  border-radius: 2rem;
  box-shadow: 6px 6px 0px 1px rgb(255, 206, 39);
  transition: all 0.2s;
  border: 2px solid rgb(255, 206, 39);
  background-color: #41b5ff;
  border: 2px solid rgb(255, 206, 39);
}

.frontend_container {
  transition: transform 0.6s;
}

.backend_container {
  transition: transform 0.6s;
}

.frontend_container:hover {
  background: transparent;
  background-color: #41b5ff;
  transition: var(--transition);
}

.frontend_container:hover,
.backend_container:hover {
  transition: var(--transition) 0.2s;
  background-color: transparent;
  cursor: pointer;
}

.frontend_heading,
.backend_heading {
  margin: 2rem 1.9rem auto;
}

.frontend_heading h3,
.backend_heading h3 {
  color: rgb(255, 206, 39);
  font-weight: 900;
  font-size: 1.8rem;
  /* text-shadow: 0px 0px 5px rgb(190, 190, 190); */
}

.backend_container:hover .backend_heading h3,
.frontend_container:hover .frontend_heading h3 {
  color: #41b5ff;
  font-weight: 900;
  font-size: 1.8rem;
  text-shadow: none;
}

.stack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.circle-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1rem;
  margin-left: 2rem;
  margin-right: 1rem;
}

.circle-container img {
  width: 13rem;
}

.about {
  margin-right: 2rem;
  margin-left: 2rem;
}

.about p {
  text-align: left;
  font-size: 1.1rem;
}
@media only screen and (max-width: 1650px) {
  .about p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1545px) {
  .frontend_container,
  .backend_container {
    width: 40%;
  }
  .frontend_container {
    margin-right: 4rem;
  }
  .backend_container {
    margin-left: 4rem;
  }
}

@media only screen and (max-width: 1120px) {
  .frontend_heading,
  .backend_heading {
    margin: 1.7rem 1.9rem auto;
  }
  .circle-container {
    margin-left: 1rem;
    margin-right: 0rem;
  }
  .circle-container img {
    width: 16rem;
  }
}

@media only screen and (max-width: 1070px) {
  .experienced_container {
    flex-direction: column;
  }
  .frontend_container,
  .backend_container {
    width: 70%;
    margin: 0;
    transform: none;
  }

  .frontend_container:hover,
  .backend_container:hover {
    transition: none;
  }
  .experienced_container:hover .frontend_container,
  .experienced_container:hover .backend_container {
    transform: none;
  }
  .circle-container {
    margin-left: 2rem;
    margin-right: 0rem;
  }
  .circle-container img {
    width: 10rem;
  }
  .about p {
    font-size: 1.15rem;
  }
}

@media only screen and (max-width: 940px) {
  .circle-container img {
    width: 10rem;
  }
  .frontend_container,
  .backend_container {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .experienced_container {
    flex-direction: column;
  }
  .frontend_container,
  .backend_container {
    width: 90%;
    margin: auto;
  }

  .about p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  .circle-container img {
    width: 13rem;
  }
  .about {
    margin-right: 1.5rem;
    margin-left: 1rem;
  }
  #experience {
    background-color: #eeeeee;
  }
}

@media only screen and (max-width: 556px) {
  .about-heading {
    font-size: 1rem;
  }
  .frontend_heading,
  .backend_heading {
    margin: 1rem 1.9rem auto;
  }
  .frontend_heading h3,
  .backend_heading h3 {
    font-size: 1.6rem;
  }
  .about p {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 380px) {
  .circle-container {
    margin-left: 0.9rem;
  }
  .about {
    margin-right: 1.2rem;
  }
  .frontend_heading,
  .backend_heading {
    margin: 0.5rem 1.8rem auto;
  }
  .stack {
    margin-top: -0.5rem;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
}

section {
  margin-top: 3rem;
}

section > p {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

section > h1,
section > p {
  text-align: center;
  color: rgb(255, 206, 39);
  margin: 1.5em;
  margin-bottom: 1em;
  margin-top: 0em;
}
