/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */

input {
  background-color: rgb(14, 14, 25);
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.register_btn {
  padding: 1.5em 5em;
  background: #efefef;
  border: none;
  border-radius: 1em;
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .2rem;
  text-align: center;
  outline: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.registration {
  font-family: 'Blackpast';
  /* font-family: 'Poppins',sans-serif; */
}
.register_btn {
  background-color: rgb(255, 206, 39) !important;
  text-align: center;
  margin: auto;
  height: 2.5em;
  width: 100%;
  border-radius: 1em;
  transition: all 0.4s;
}

.btn:hover {
  width: 100%;
}
.w-1\/4 {
  width: 40%;
}
.upperDiv {
  display: flex;
  justify-content: center;
}
.upperDiv h2 {
  font-size: 2rem;
}
.whole_form {
  display: flex;
  justify-content: center;
  box-shadow: inset 0px 92px 92px 1px #eeeeee;
}
.whole_form::before {
  content: '';
}
.upperLower {
  padding: 3rem 0;
  width: 90%;
  padding: auto 3rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(15px);
  margin: auto auto 5rem auto;
  border-radius: 1.5em;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
.lowerdiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.input_container input,
.input_container select {
  color: black;
}

.input_container input:hover,
.input_container select:hover {
  /* background-color: rgb(83, 189, 231); */
}

.input_container input:focus,
.input_container select:focus {
  /* background-color: rgb(83, 189, 231); */
  outline: none;
  color: black;
  box-shadow: none;
}

.end_image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.end_image:hover h1 {
  margin-left: 5rem;
  visibility: visible;
}

.end_image h1,
.end_image img {
  transition: all 0.5s;
}

.end_image h1 {
  font-weight: 900;
  visibility: hidden;
}

.input-flex {
  flex-direction: row;
}
.input-flex label {
  font-size: 1.25rem;
}
.form {
  width: 40%;
  margin: auto;
}
.img_qr_one,
.img_qr_two {
  /* margin: 3em; */
  /* margin-top: 0; */
  margin: 1em auto;
}
.QR{
  width: 40%;
  margin: auto;
  margin-bottom: 5em;
}
.img_qr_one {
  display: none;
}

.img_qr_one h5,
.img_qr_two h5 {
  font-size: 1.05rem;
}
.Select {
  width: 100%;
  /* height: 2.5em; */
  padding-right: 100px;
}

.btn_class {
  width: 50%;
  margin: auto;
}

.register_btn {
  background-color: rgb(255, 206, 39);
  /* height: 2.5rem;   */
  margin-bottom: 1em;
}

@media only screen and (max-width: 600px) {
  .lowerdiv {
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
  
  .register-imgs {
    display: flex;
    justify-content: space-around;
    margin: auto;
  }
  .w-1\/4 {
    width: 200px;
  }
  .mb-12 {
    margin-bottom: 0rem;
  }
  .end_image {
    width: 50%;
  }
  .img_qr_one,
  .img_qr_two {
    /* margin: 0em;
    margin-right: 1em; */
    margin: auto;
    width: 85%;
  }
  .mx-auto {
    margin-left: 0;
    margin-right: 0;
  }
  .register-imgs {
    width: 75%;
    justify-content: space-evenly;
    align-items: center;
  }
  .form {
    margin: 0;
    width: 90%;
    margin-top: 2rem;
  }
  .QR{
    width: 70%;
    margin: auto;
    margin-bottom: 2em;
  }
}
@media only screen and (max-width: 900px) {
  .lowerdiv {
    flex-direction: column;
  }

  .img_qr_one {
    display: block;
  }
  .img_qr_one .end_image {
    display: none;
  }
  .img_qr_two h5,
  .img_qr_two .register-imgs .QR,
  .img_qr_two h3 {
    display: none;
  }
  .form {
    width: 80%;
    margin: auto;
  }
}
@media only screen and (max-width: 768px) {
  .input-flex {
    flex-direction: column;
  }
  .register-imgs {
    flex-direction: column;
    justify-content: center;
  }
  .end_image {
    width: 80%;
    margin-top: 1.5rem;
  }
  .form-control {
    padding: 0.6rem 0.75rem;
  }
  .p-2 {
    padding: 0.64rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1.3rem !important;
    margin-top: 0rem !important;
  }
}

@media only screen and (max-width: 560px) {
  .img_qr_one {
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .upperDiv h2 {
    font-size: 1.45rem;
  }
  .img_qr_one h5,
  .img_qr_two h5 {
    font-size: 1.05rem;
  }
  .input-flex label {
    font-size: 1rem;
  }
  .upperDiv h2 {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 400px) {
  .form {
    width: 88%;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 0.7rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .form-control {
    padding: 0.5rem 0.75rem;
  }
  .end_image {
    width: 90%;
  }
  .p-1 {
    padding: 0rem;
  }
  .upperLower {
    box-shadow: 0 8px 16px 0 rgba(31, 38, 135, 0.37);
    margin: auto auto 2rem auto;
  }
  label {
    margin-bottom: 0.3rem;
  }
}
