* {
  font-family: Arial, Helvetica, sans-serif;
}

.Technologies {
  text-align: center;
  margin: 10px;
  margin-top: 20px;
  color: rgb(255, 206, 39);
  font-family: 'Blackpast';
}
.html11 {
  height: 50px;
  width: 1em;
}
.strips {
  backdrop-filter: blur(15px);
}
.techContainer {
  height: 150px;
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

.techContainer img {
  width: 100px;
  height: 80px;
  margin-right: 1.4rem;
}

#strips {
  overflow-x: hidden;
  margin-top: 100px;
}


.strip1,
.strip2,
.strip3 {
  width: 100%;
  height: 90px;
  margin: 4em auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 30px;
  align-items: center;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(15px);
}
.strip1 {
  margin-top: 2.5em;
}
.strip1,
.strip3 {
  /* backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px); */
  /* background: linear-gradient(to bottom, rgb(112, 197, 221), #eeeeee); */
  box-shadow: 0 8px 16px 0 rgba(31, 38, 135, 0.37);
}

.strip2 {
  /* backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px); */
  /* background: linear-gradient(to bottom, rgb(255, 206, 39), #eeeeee); */
  box-shadow: 0 8px 16px 0 rgba(31, 38, 135, 0.37);
}

.strip1-text,
.strip3-text {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 8em;
  animation-direction: alternate;
  animation-name: move;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.strip2-text {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 8em;
  animation-direction: alternate;
  animation-name: move;
  animation-duration: 14s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.repeat-strip1 {
  display: flex;
  flex-direction: row;
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-800px);
  }
}

@media only screen and (max-width: 1170px) {
  .strip1,
  .strip2,
  .strip3 {
    height: 70px;
    font-size: 24px;
  }
}
@media only screen and (max-width: 700px) {
  .strip1,
  .strip2,
  .strip3 {
    height: 55px;
    font-size: 16px;
  }

  .techContainer img {
    width: auto;
    height: 45px;
    margin-right: 1rem;
  }
}
