* {
  font-family: Arial, Helvetica, sans-serif;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
.techno_heading {
  font-family: 'Blackpast', sans-serif;
}
.logo-conatiner {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
}

.logo-conatiner img {
  width: 13rem;
  margin: 2rem;
}

.title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.title {
  position: relative;
  font-size: 30px;
  text-align: center;
}
.img1,
.img2 {
  width: 500px;
  height: 500px;
  margin-top: 150px;
  z-index: -1;
}

.presents {
  font-size: 20px;
  /* 1.5 */
}
.club_name1 {
  font-size: 1vw;
}
.techno_heading {
  font-size: 5.1rem;
  color: rgb(255, 206, 39);
}

.extra {
  height: 100vh;
}
@media only screen and (max-width: 1290px) {
  .logo-conatiner img {
    width: 10rem;
  }
}

@media only screen and (max-width: 700px) {
  .logo-conatiner img {
    width: 7rem;
  }
  .title_container {
    flex-direction: column;
  }

  .img1,
  .img2 {
    width: 200px;
    height: 200px;
    margin-top: 0;
  }

  .club_name1 {
    font-size: 5vw;
  }

  .presents {
    font-size: 3vw;
  }

  .techno_heading {
    font-size: 12vw;
    color: rgb(255, 206, 39);
  }
}

@media (min-width: 699px) {
  .club_name1 {
    font-size: 3vw;
  }

  .presents {
    font-size: 2vw;
  }

  .techno_heading {
    font-size: 8vw;
    color: rgb(255, 206, 39);
  }
}

@media only screen and (max-width: 490px) {
  .logo-conatiner img {
    width: 5rem;
    margin: 1rem;
  }
}
